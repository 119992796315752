import request from '@/utils/request'; // 订单创建

export function create(data) {
  return request({
    url: '/order/create',
    method: 'post',
    data: data
  });
} // 订单支付

export function pay(data) {
  return request({
    url: '/order/pay',
    method: 'post',
    data: data
  });
} // 订单详情

export function detail(data) {
  return request({
    url: '/order/detail',
    method: 'get',
    params: data
  });
} // 订单列表

export function list(data) {
  return request({
    url: '/order/list',
    method: 'get',
    params: data
  });
} // 用户以往记录

export function orderRecords(data) {
  return request({
    url: '/order/user_message',
    method: 'get',
    params: data
  });
} // 取消订单

export function cancel(data) {
  return request({
    url: '/order/cancel',
    method: 'post',
    data: data
  });
}