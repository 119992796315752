import request from '@/utils/request'; // 门店信息

export function storeInfo(data) {
  return request({
    url: '/store/info',
    method: 'get',
    params: data
  });
} // 获取门店可预约的时间段

export function period(data) {
  return request({
    url: '/store/period',
    method: 'get',
    params: data
  });
} // 下预约单

export function appoint(data) {
  return request({
    url: '/store/appoint',
    method: 'post',
    data: data
  });
} // 获取预约详细信息

export function getAppointInfo(data) {
  return request({
    url: '/store/getAppointInfo',
    method: 'get',
    params: data
  });
} // 获取预约列表

export function getAppointList(data) {
  return request({
    url: '/store/appointList',
    method: 'get',
    params: data
  });
} // 获取案例列表

export function casesList(data) {
  return request({
    url: '/store/casesList',
    method: 'get',
    params: data
  });
} // 更改预约状态

export function changeAppoint(data) {
  return request({
    url: '/store/changeAppoint',
    method: 'post',
    data: data
  });
} // 顾客预约详情

export function appointInfo(data) {
  return request({
    url: '/store/appointInfo',
    method: 'post',
    data: data
  });
} // 获取订单的养眼记录

export function eyeRecordList(data) {
  return request({
    url: '/store/eyeRecordList',
    method: 'get',
    params: data
  });
}