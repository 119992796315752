import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/calendar/style";
import _Calendar from "vant/es/calendar";
import "vant/es/goods-action-button/style";
import _GoodsActionButton from "vant/es/goods-action-button";
import "vant/es/goods-action/style";
import _GoodsAction from "vant/es/goods-action";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { detail } from '@/api/order';
import { period, appoint } from '@/api/store';
import { setNowFormatDate } from '@/utils/common';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Toast.name, _Toast), _defineProperty(_components, _Swipe.name, _Swipe), _defineProperty(_components, _SwipeItem.name, _SwipeItem), _defineProperty(_components, _GoodsAction.name, _GoodsAction), _defineProperty(_components, _GoodsActionButton.name, _GoodsActionButton), _defineProperty(_components, _Calendar.name, _Calendar), _components),
  data: function data() {
    return {
      detailData: {
        product: {},
        order: {},
        user_message: {}
      },
      images: ['https://img.yzcdn.cn/vant/apple-1.jpg', 'https://img.yzcdn.cn/vant/apple-1.jpg'],
      appointmentDate: setNowFormatDate(new Date(), 'day'),
      calendarShow: false,
      timeSlot: [],
      // 渲染时间段
      selectedTimeId: '',
      // 选中的时间段id
      currentIndex: '' // 选中的下标

    };
  },
  created: function created() {
    this.getPeriod();
    this.getOrderInfo(this.$route.query.id);

    _Dialog.confirm({
      title: '',
      message: '当天临时预约必须先和店长确认，否则可能无法安排！',
      showCancelButton: false
    }).then(function () {});
  },
  methods: {
    // 获取详情
    getOrderInfo: function getOrderInfo(id) {
      var _this = this;

      detail({
        order_id: id
      }).then(function (res) {
        if (res.error.code === 0) {
          _this.detailData = res.data;
        }
      });
    },
    // 获取门店可预约的时间段
    getPeriod: function getPeriod() {
      var _this2 = this;

      period({
        date: this.appointmentDate
      }).then(function (res) {
        if (res.error.code === 0) {
          _this2.timeSlot = res.data;
        }
      });
    },
    // 选中日期
    onConfirm: function onConfirm(date) {
      this.calendarShow = false;
      this.appointmentDate = setNowFormatDate(new Date(date), 'day');
      this.getPeriod();
      this.currentIndex = null;
      this.selectedTimeId = null;
    },
    // 选中时间段
    choose: function choose(item, index) {
      this.currentIndex = index;
      this.selectedTimeId = item.id;
    },
    // 确定预约
    confirm: function confirm() {
      var _this3 = this;

      if (!this.selectedTimeId) {
        _Toast('请选择预约时间段');

        return false;
      }

      var data = {
        order_id: this.detailData.order.order_id,
        time_id: this.selectedTimeId,
        date: this.appointmentDate
      };
      appoint(data).then(function (res) {
        if (res.error.code === 0) {
          _Toast('预约成功');

          _this3.$router.push({
            name: 'package'
          });
        }
      });
    },
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};