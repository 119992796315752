var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "订单详情",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "content-title" }, [_vm._v("订单内容")]),
        _c("div", { staticClass: "content-richText" }, [
          _c("p", [
            _vm._v("患者姓名：" + _vm._s(_vm.detailData.user_message.name)),
          ]),
          _c("p", [
            _vm._v("商品名称：" + _vm._s(_vm.detailData.product.store_name)),
          ]),
          _c("p", [
            _vm._v("商品价格：" + _vm._s(_vm.detailData.product.price)),
          ]),
          _c("p", [
            _vm._v(
              " 总次数/剩余次数：" +
                _vm._s(_vm.detailData.order.total_appointment_num) +
                " / " +
                _vm._s(_vm.detailData.order.appointment_num) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("van-cell", {
        staticStyle: { "margin-top": "10px", padding: "10px" },
        attrs: { title: "选择日期", value: _vm.appointmentDate },
        on: {
          click: function ($event) {
            _vm.calendarShow = true
          },
        },
      }),
      _c("van-calendar", {
        on: { confirm: _vm.onConfirm },
        model: {
          value: _vm.calendarShow,
          callback: function ($$v) {
            _vm.calendarShow = $$v
          },
          expression: "calendarShow",
        },
      }),
      _vm.timeSlot.length !== 0
        ? _c(
            "div",
            { staticClass: "choose-container" },
            [
              _vm._l(_vm.timeSlot, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: [
                      "choose-item",
                      item.is_show ? "choose-click" : "choose-no-click",
                      _vm.currentIndex === index ? "choose-selected" : "",
                    ],
                    on: {
                      click: function ($event) {
                        item.is_show ? _vm.choose(item, index) : ""
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              _c("div", { staticClass: "placeholder" }),
              _c("div", { staticClass: "placeholder" }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "van-goods-action",
        [
          _c("van-goods-action-button", {
            attrs: { type: "info", text: "确认预约" },
            on: { click: _vm.confirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }